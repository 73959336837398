import { Box, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { taskTypes } from "../../models/Task";
import { Institution, User } from "../../models";
import CollegeDropdown from "./CollegeDropdown";
import StudentDropdown from "./StudentDropdown";

interface FilterData {
  due_date_start: string;
  due_date_end: string;
  completed: string;
  task_type: string;
  user: User|null;
  college: Institution|null;
}

const confirmOptions = [
  ['Any', ''],
  ['Yes', 'Yes'],
  ['No', 'No']
]

const FilterTasks = ({ title, filters, onCancel, onSave, showUsers = false, showColleges = false }: { title: string, filters?: FilterData, onCancel: () => void, onSave: (filters: FilterData) => void, showUsers?: boolean, showColleges?: boolean }) => {
  const [dueDateStart, setDueDateStart] = useState(filters?.due_date_start || '');
  const [dueDateEnd, setDueDateEnd] = useState(filters?.due_date_end || '');
  const [completed, setCompleted] = useState(filters?.completed || '');
  const [taskType, setTaskType] = useState(filters?.task_type || '');
  const [college, setCollege] = useState(filters?.college || null);
  const [user, setUser] = useState(filters?.user || null);


  const handleSave = () => {
    const filters: FilterData = {
      due_date_start: dueDateStart,
      due_date_end: dueDateEnd,
      completed: completed,
      task_type: taskType,
      college,
      user,
    };
    onSave(filters);
  }

  const clearFilters = () => {
    setDueDateStart('');
    setDueDateEnd('');
    setCompleted('');
    setTaskType('');
    setCollege(null);
    setUser(null);
  }

  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FilterAltOutlinedIcon sx={{ pr: 1 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>{title}</Typography>
          <SecondaryButton
            variant="outlined"
            startIcon={<CancelOutlinedIcon />}
            onClick={clearFilters}
          >
            Clear
          </SecondaryButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }}>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Due Date After"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dueDateStart}
                  onChange={(e) => setDueDateStart(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Due Date Before"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dueDateEnd}
                  onChange={(e) => setDueDateEnd(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          <FormControl>
            <InputLabel>Completed</InputLabel>
            <Select
              label="Completed"
              value={completed}
              onChange={(e) => setCompleted(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Task Type</InputLabel>
            <Select
              label="Task Type"
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
            >
              {taskTypes.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {showUsers && (
            <StudentDropdown value={user} onChange={setUser} filteredUsers={[]} />
          )}

          {showColleges && (
            <CollegeDropdown value={college} onChange={setCollege} />
          )}          

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default FilterTasks;
export { FilterData };